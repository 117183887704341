import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import dotsIcon from "src/svg/dots.svg"

export const ProductCard = ({ name, image, slug, price }) => {
  return (
    <Wrap to={`/product/${slug}`}>
      <Inner>
        <Image fluid={image.fluid} alt={name} />
        <h3>{name}</h3>
        <BottomPane>
          <h4>
            {price} <span>BYN</span>
          </h4>
          <Details>
            <img src={dotsIcon} alt=""></img>
          </Details>
        </BottomPane>
      </Inner>
    </Wrap>
  )
}

const Inner = styled.div`
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    transition: all 0.3s;
    margin: 0;
    font-weight: normal;
    line-height: 1.4;
  }

  h4 {
    margin: 0;
    font-weight: 900;
    font-size: 32px;

    span {
      font-size: 16px;
    }
  }
`

const Wrap = styled(Link)`
  transition: all 0.3s;
  display: block;
  background-color: #fff;
  border-radius: 8px;

  flex-basis: calc(100% / 3 - 32px);
  margin: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-basis: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) and (max-width: ${({
      theme,
    }) => theme.breakpoints.l}) {
    flex-basis: calc(100% / 2 - 32px);
  }

  &:hover {
    ${Inner} {
      h3 {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

const Image = styled(Img)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;

  margin-bottom: 24px;

  width: 100%;
  height: 320px;
`

const BottomPane = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  h4 {
    color: ${({ theme }) => theme.colors.contrast};
  }
`

const Details = styled.span`
  display: inline-block;

  img {
    display: block;
  }
`
