import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"
import Swiper from "react-id-swiper"
import Img from "gatsby-image"

import { Container } from "src/components/Container"

const params = {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  effect: "fade",
}

export const Hero = () => {
  return (
    <StaticQuery
      query={query}
      render={({ slider: { slides } }) => (
        <Container>
          <Swiper {...params}>
            {slides.map(({ id, link, image }) => (
              <Slide key={id} to={link}>
                <Img fluid={image.fluid}></Img>
              </Slide>
            ))}
          </Swiper>
        </Container>
      )}
    ></StaticQuery>
  )
}

const query = graphql`
  query {
    slider: datoCmsHomepageSlider {
      slides {
        link
        id
        image {
          fluid(
            maxWidth: 1010
            imgixParams: { fm: "jpg", auto: "compress, format" }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`

const Slide = styled(Link)`
  display: block;

  img {
    display: block;
    border-radius: 8px;
  }
`
