import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import { Container } from "src/components/Container"
import { WidgetHead } from "src/components/WidgetHead"
import { ProductCard } from "src/components/ProductCard"

export const PopularProducts = () => {
  return (
    <StaticQuery
      query={query}
      render={({ allDatoCmsProduct: { nodes } }) => (
        <Wrap>
          <Container>
            <WidgetHead>Популярные товары</WidgetHead>
            <Inner>
              {nodes.map(({ slug, image, name, id, price }) => (
                <ProductCard
                  key={id}
                  {...{ slug, image, name, price }}
                ></ProductCard>
              ))}
            </Inner>
          </Container>
        </Wrap>
      )}
    ></StaticQuery>
  )
}

const query = graphql`
  query {
    allDatoCmsProduct(limit: 9, sort: { fields: name }) {
      nodes {
        id
        slug
        image {
          fluid(
            maxWidth: 320
            imgixParams: { fm: "jpg", auto: "compress, format" }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        name
        price
      }
    }
  }
`

const Wrap = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`

const Inner = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;
`
