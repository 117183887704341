import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"

import { WidgetHead } from "src/components/WidgetHead"
import { Container } from "src/components/Container"

export const RecentNews = () => {
  return (
    <StaticQuery
      query={query}
      render={({ blogPosts: { nodes } }) => (
        <>
          {nodes.length > 0 && (
            <Container>
              <WidgetHead>Поcледние новости</WidgetHead>
              <Inner>
                <Pane>
                  <Card primary="true" to={`/blog/${nodes[0].slug}`}>
                    <Date>{nodes[0].meta.createdAt}</Date>
                    <Text>{nodes[0].title}</Text>
                  </Card>
                </Pane>
                <Pane>
                  {nodes.slice(1, 3).map(({ title, slug, meta, id }) => (
                    <Card to={`/blog/${slug}`} key={id}>
                      <Date>{meta.createdAt}</Date>
                      <Text>{title}</Text>
                    </Card>
                  ))}
                </Pane>
                <Pane>
                  {nodes.slice(3, 5).map(({ title, slug, meta, id }) => (
                    <Card to={`/blog/${slug}`} key={id}>
                      <Date>{meta.createdAt}</Date>
                      <Text>{title}</Text>
                    </Card>
                  ))}
                </Pane>
              </Inner>
            </Container>
          )}
        </>
      )}
    ></StaticQuery>
  )
}

const query = graphql`
  query {
    blogPosts: allDatoCmsBlog(limit: 5) {
      nodes {
        title
        slug
        meta {
          createdAt(formatString: "DD.MM.YYYY")
        }
        id
      }
    }
  }
`

const Inner = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;
`

const Card = styled(Link)`
  display: block;
  background-color: #fff;
  border-radius: 6px;
  padding: 32px 16px;

  flex-basis: calc(100% / 3 - 32px);
  margin: 32px 16px;

  ${({ primary }) =>
    primary &&
    `
    height: calc(100% - 64px);
  `}

@media (max-width: ${({ theme }) => theme.breakpoints.m}) {
  ${({ primary }) =>
    primary &&
    `
    margin-bottom: 0;
    height: initial;
  `}
  }
`

const Pane = styled.div`
  flex-basis: ${100 / 3}%;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: ${100 / 2}%;

    &:last-child {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-basis: 100%;
  }
`

const Text = styled.h4`
  line-height: 140%;
  margin: 0;
`

const Date = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  margin-bottom: 16px;
`
